import React, { useState, useEffect } from "react";
import ComputerSoftwareLogo from "../../../assets/images/computer-software-industry.svg";
import ECommerceLogo from "../../../assets/images/e-commerce-industry.svg";
import PharmaBiotechLogo from "../../../assets/images/pharma-biotech-industry.svg";
import ProfessionalLogo from "../../../assets/images/professional-service.svg";
import SeeMoreIcon from "../../../assets/images/campaign-industry-dots.svg";
import * as actions from "../../../main/actions/Campaings";

function Industries() {
  const industries = [
    {
      logo: ComputerSoftwareLogo,
      title: "Computer Software",
    },
    {
      logo: ECommerceLogo,
      title: "B2B E-Commerce",
    },
    {
      logo: PharmaBiotechLogo,
      title: "Pharma & Biotech",
    },
    {
      logo: ProfessionalLogo,
      title: "Professional Service",
    },
  ];

  return (
    <div className="industries d-flex justify-content-center align-items-center">
      <div className="pl-5 pr-5">
        <div className="row header-box d-flex justify-content-center align-items-center pl-3 pr-3">
          We work with lots of industries
        </div>
        <div className="row description-box d-flex justify-content-center align-items-center mt-3 pl-3 pr-3">
          Our Campaign Manager product has helped scale businesses in a variety
          of industries
        </div>
        <div className="row figurative-box mt-4">
          {industries.map((industry) => {
            return (
              <div className="col d-flex justify-content-center mt-3">
                <div className="industry-box d-flex justify-content-center align-items-center">
                  <div className="logo-title-box">
                    <div className="logo-box d-flex justify-content-center align-items-center">
                      <img src={industry.logo} />
                    </div>
                    <div className="title-box d-flex justify-content-center align-items-center mt-2">
                      {industry.title}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <div className="col d-flex justify-content-center mt-3">
            <div className="industry-box d-flex justify-content-center align-items-center bg-white see-more__box">
              <div>
                <div className="d-flex justify-content-center align-items-center">
                  <img src={SeeMoreIcon} />
                </div>
                <div
                  className="d-flex justify-content-center align-items-center mt-3"
                  style={{ fontWeight: "600" }}
                >
                  and more!
                </div>
                <div className="d-flex justify-content-center align-items-center">
                  <div
                    className="read-case-study__btn pt-2 pb-2 pl-3 pr-3 mt-3"
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      window.location.href = "/customer_stories";
                    }}
                  >
                    Read case studies
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Industries;
