import React from "react";
import PropTypes from 'prop-types';
import { useState } from "react";
import homeBanner from "../../../assets/images/home-banner.png";
import homeBanner2x from "../../../assets/images/home-banner@2x.png";
import campaignPlayButton from "../../../assets/images/campaign-play-button.svg";
import { Modal } from "react-bootstrap";

const CampaignManagerVideoModal = (props) => {
  return (
    <Modal
      show={props.show}
      onHide={() => {
        props.onClose();
      }}
    >
      <Modal.Header closeButton>Campaign Manager Introduction</Modal.Header>
      <Modal.Body>
        <iframe
          src="https://www.youtube.com/embed/6iWbgdjA7oY?autoplay=1"
          frameborder="0"
          allow="autoplay; encrypted-media"
          allowfullscreen
          title="video"
          height="100%"
          width="100%"
        />
      </Modal.Body>
    </Modal>
  );
};

CampaignManagerVideoModal.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

const Hero = () => {
  const [playVideo, setPlayVideo] = useState(false);

  return (
    <section className="home-hero">
      <CampaignManagerVideoModal
        show={playVideo}
        onClose={() => {
          setPlayVideo(false);
        }}
      />
      <img
        src={campaignPlayButton}
        className="campaign-play-button"
        onClick={() => {
          setPlayVideo(true);
        }}
      />
      <img
        src={homeBanner}
        className="hero-image"
        srcSet={homeBanner + " 1x, " + homeBanner2x + " 2x"}
        alt="Hero Image"
      />
    </section>
  );
};

export default Hero;
