import React from "react";
import AnalyticsLogo from "../../../assets/images/analytics.svg";
import HydrusLogo from "../../../assets/images/hydrus.svg";
import NextPlayLogo from "../../../assets/images/nextplay.svg";
import SixIndustriesLogo from "../../../assets/images/six-industries.svg";
import UserEvidenceLogo from "../../../assets/images/user-evidence.svg";
import RiwiLogo from "../../../assets/images/riwi.svg";

export default function TrrustedCompanies() {
  return (
    <div className="trusted-companies mt-5">
      <div className="label text-md-center">
        TRUSTED BY GREAT COMPANIES LIKE
      </div>
      <div className="d-flex justify-content-center">
        <div className="row mt-2" style={{columnGap: "2rem"}}>
          <div className="col d-flex align-items-center justify-content-center mt-3">
            <img src={RiwiLogo} style={{height: "80%"}} />
          </div>
          <div className="col d-flex align-items-center justify-content-center mt-4">
            <img src={AnalyticsLogo} />
          </div>
          <div className="col d-flex align-items-center justify-content-center mt-4">
            <img src={HydrusLogo} />
          </div>
          <div className="col d-flex align-items-center justify-content-center mt-4">
            <img src={NextPlayLogo} />
          </div>
          <div className="col d-flex align-items-center justify-content-center mt-4">
            <img src={SixIndustriesLogo} />
          </div>
          <div className="col d-flex align-items-center justify-content-center mt-4">
            <img src={UserEvidenceLogo} />
          </div>
        </div>
      </div>
    </div>
  );
}
