import React from "react";
import AnalyticsFingertipsImage from "../../../assets/images/analytics-fingertips-image.svg";

function Analytics() {
  return (
    <div className="analytics-at-finger-tips row">
      <div className="col d-flex justify-content-center align-items-center analytics-description-container">
        <div style={{ width: "466px", maxWidth: "95%" }} className="pl-4">
          <div className="analytics-header">Analytics at Your Fingertips</div>
          <div className="mt-3 analytics-description">
            Get the best in class analytics to better understand your target
            audience, optimize changes, focus on what works best.
          </div>
          <a
            className="mt-3 btn btn-primary see-how-btn text-white"
            href="/account/campaign_manager/new"
          >
            See how
          </a>
        </div>
      </div>
      <div className="col d-flex justify-content-center align-items-center analytics-image-container">
        <img src={AnalyticsFingertipsImage} />
      </div>
    </div>
  );
}

export default Analytics;
