import React from "react";
import NetPlay from "../../../assets/images/netplay.svg";
import Lesley from "../../../assets/images/lesley.png";
import DoubleQuote from "../../../assets/images/double-quote.svg";
import CampaignTestimonial from "../../../assets/images/campaign-testimonial.svg";

const Testimonial = () => {
  return (
    <section
      className="home-testimonial"
      // style={{ backgroundImage: 'url("' + CampaignTestimonial + '")' }}
    >
      <img src={DoubleQuote} className="double-quote" alt="Quotation Mark" />
      <div className="container-fluid">
        <div className="left">
          <img src={NetPlay} alt="NetPlay" />
          <p className="text">
            “As a founder with tons going on, being able to delegate certain
            tasks is critical. Campaign Manager saves me hours of time weekly
            while still delivering on the promise of quality conversations.”
          </p>
          <div className="meta d-flex align-items-center">
            <div className="img d-lg-none w-25 w-md-15">
              <img
                src={Lesley}
                className="testimonial-image img-fluid m-0"
                alt="Lesley Toche"
              />
            </div>
            <div className="desc ml-5 ml-lg-0 d-flex flex-column justify-content-center">
              <h5 className="author">Lesley Toche</h5>
              <p className="designation">CEO, Nextplay</p>
            </div>
          </div>
        </div>
        <div className="right d-none d-lg-block">
          <img src={Lesley} className="testimonial-image" alt="Lesley Toche" />
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
