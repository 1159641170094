import React from 'react';
import ReactDOM from 'react-dom';
import AccountApp from '../src/modules/Campaings';

document.addEventListener('DOMContentLoaded', () => {
  const node = document.getElementById('account_react');
  const data = JSON.parse(node.getAttribute('data')) || {};
  const dataPoll = parseInt(node.getAttribute('data-poll')) || null;
  const dataMagiklinkID = parseInt(node.getAttribute('data-magiclinkid')) || null;
  const magickLinkCta = node.getAttribute('magic_link_cta') || null;

  let divToBeAppended = document.createElement('div');
  divToBeAppended.id = "root";

  ReactDOM.render(
    <AccountApp userData={data} dataPoll={dataPoll} dataMagiklinkID={dataMagiklinkID} magickLinkCta={magickLinkCta} />,
    document.body.appendChild(divToBeAppended),
  );
});
