import React from 'react';
import Slider from "react-slick";
import ClientStars from "../../../assets/images/clients-stars.svg";
import QuoteImage from "../../../assets/images/quote.svg";

const Clients = (props) => {
  const settings = {
    dots: true,
    slidesToShow: 3,
    centerMode: false,
    autoplay: false,
    speed: 500,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const clients = [
    {
      quote:
        "“Increased our pipeline by 3x. Representatives book meetings directly to our calendar, which gives us back more time to do other things rather than spending time going back and forth booking meetings.”",
      person: "Chief Operating Officer",
      company: "11-50 Software Company",
    },
    {
      quote:
        "Campaign Manager helped us generate a steady stream of leads. There are a lot of things to love about the service. First and foremost, it works - we have anywhere from two to five calls scheduled each week. These are quality leads we can convert into actual sales.",
      person: "Panos Moutafis",
      company: "Co-founder & CEO Zenus, Inc",
    },
    {
      quote:
        "Accountstory has created at least 7 opportunities to our enterprise pipeline. Overall, the additional new meetings have helped synergize the sales org.",
      person: "Account Executive",
      company: "11-50 Software Company",
    },
  ];

  return (
    <div className="clients-container position-relative mt-6">
      <div className="label text-md-center clients-header d-flex justify-content-center">
        What our clients say
      </div>
      <div className="container u-space-3 mt-3 p-0">
        <Slider {...settings}>
          {clients.map((client, i) => {
            return (
              <div
                className={
                  "mt-5 d-flex justify-content-center pl-3" +
                  (i == 2 ? " pr-3" : " pr-2")
                }
              >
                <div className="client-quote__container">
                  <div className="row mt-2 ml-2">
                    <img src={QuoteImage} />
                  </div>
                  <div className="row ml-2 mr-2 mt-2 client-words">
                    {client.quote}
                  </div>
                  <div className="row mt-3  ml-2 mr-2">
                    <text className="position-title">{client.person}</text>
                    <br />
                    <text className="company-size">{client.company}</text>
                  </div>
                  <div className="row ml-1 mt-3">
                    <img src={ClientStars} />
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default Clients;
