import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { CSSTransition } from "react-transition-group";
import "../assets/styles/components/header.scss";

const Header = ({
  userData,
  magickLinkCta,
  showSearchBar,
  campaignManager,
}) => {
  const [showMenu, setShowMenu] = useState(false);
  const dropdownMenu = useRef(null);
  const [isCampaignMagicLink, setCampaignMagicLink] = useState(false);
  useEffect(() => {
    if (magickLinkCta) {
      setCampaignMagicLink(true);
    }
  });

  const ctaUrl = () => {
    return isCampaignMagicLink || campaignManager
      ? "/account/campaign_manager/new"
      : "/users/sign_up?plan_type=free";
  };

  const toggleShow = function (e) {
    setShowMenu(!showMenu);
  };

  const setIsSignedInDefault = () => {
    if (!userData || Object.keys(userData).length === 0) return false;
    else return true;
  };

  const [isSignedIn] = useState(setIsSignedInDefault());

  return (
    <header
      // className={"site-header" + (isSignedIn ? " mobile-view-none" : "")}
      className="site-header"
      id="header"
    >
      <div className="container nav-bar__container">
        <nav className="js-mega-menu navbar u-header__navbar navbar-expand-md hs-menu-initialized hs-menu-horizontal">
          <a className="navbar-brand" href="/" aria-label="Accountstory Logo">
            <img
              width="175px"
              src="/assets/svg/logos/Accountstory_Logo.svg"
              alt="Accountstory"
              className="ml-6 account-logo__header"
            />
          </a>
          {/* {showSearchBar && <SearchBar />} */}
          {/* {isSignedIn && showSearchBar ? ( */}
          {isSignedIn && false ? (
            <></>
          ) : (
            <div>
              {(!isSignedIn && (
                <a className="mobile-view" href="/users/sign_up?plan_type=free">
                  <div className="btn btn-sm btn-primary u-btn-primary transition-3d-hover join-now__btn mr-3">
                    Get Started
                  </div>
                </a>
              )) ||
                (isSignedIn && (
                  <a
                    className="btn btn-sm btn-link text-secondary mobile-view"
                    href="/account/dashboard"
                  >
                    <img src="/assets/img/profile_icon.svg" height="18" />
                  </a>
                ))}
              <button
                type="button"
                className={`navbar-toggler btn u-hamburger${
                  showMenu ? "" : " collapsed"
                }`}
                aria-label="Toggle navigation"
                aria-expanded={showMenu}
                aria-controls="navBar"
                data-toggle="collapse"
                data-target="#navBar"
                onClick={toggleShow}
              >
                <span id="hamburgerTrigger" className="u-hamburger__box">
                  <span className="u-hamburger__inner"></span>
                </span>
              </button>
            </div>
          )}

          {/* {isSignedIn && showSearchBar ? (
            <></>
          ) : ( */}
            <CSSTransition
              in={showMenu}
              timeout={350}
              classNames="display"
              onEnter={() => {
                dropdownMenu.current.classList.remove("collapse");
              }}
              onExited={() => {
                dropdownMenu.current.classList.add("collapse");
                dropdownMenu.current.classList.remove("display-exit-done");
              }}
            >
              <div
                style={{ justifyContent: "flex-end" }}
                id="navBar"
                className="navbar-collapse py-0 collapse"
                ref={dropdownMenu}
              >
                <ul className="navbar-nav u-header__navbar-nav ml-lg-auto">
                  {/* <!-- Button Write a review --> */}

                  <li className="nav-item u-header__nav-item hs-mega-menu-opened  my-3">
                    <div data-event="">
                      <div className="dropdown show ">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="btn  btn-sm dropdown-toggle btn-link font-weight-bold customer-stories__nav"
                            id="productMenuLink"
                          >
                            Products
                            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11.2946 0.999599C10.9053 0.610326 10.2743 0.609982 9.88462 0.99883L6.70633 4.17019C6.31599 4.55968 5.68401 4.55968 5.29366 4.17019L2.11538 0.99883C1.72569 0.609982 1.09466 0.610326 0.705384 0.999598V0.999598C0.315811 1.38917 0.315811 2.02079 0.705385 2.41037L5.29289 6.99788C5.68342 7.3884 6.31658 7.3884 6.70711 6.99788L11.2946 2.41037C11.6842 2.02079 11.6842 1.38917 11.2946 0.999599V0.999599Z" fill="#232F5E"/>
                            </svg>



                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {DropdownItem("/campaign", "Campaign Manager")}
                            {DropdownItem("/insights", "Account Insights")}
                            {/*{DropdownItem("/chrome", "Chrome Extension")}*/}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </li>

                  {/* <!-- Pricing Button --> */}
                  <li className="nav-item u-header__nav-item hs-mega-menu-opened my-3">
                    <a
                      className="btn btn-sm btn-link font-weight-bold customer-stories__nav"
                      href="/pricing"
                    >
                      Pricing
                    </a>
                  </li>
                  <li className="nav-item u-header__nav-item hs-mega-menu-opened  my-3">
                    <div data-event="">
                      <div className="dropdown show ">
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="btn  btn-sm dropdown-toggle btn-link font-weight-bold customer-stories__nav"
                            id="productMenuLink"
                          >
                            Resources
                            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M11.2946 0.999599C10.9053 0.610326 10.2743 0.609982 9.88462 0.99883L6.70633 4.17019C6.31599 4.55968 5.68401 4.55968 5.29366 4.17019L2.11538 0.99883C1.72569 0.609982 1.09466 0.610326 0.705384 0.999598V0.999598C0.315811 1.38917 0.315811 2.02079 0.705385 2.41037L5.29289 6.99788C5.68342 7.3884 6.31658 7.3884 6.70711 6.99788L11.2946 2.41037C11.6842 2.02079 11.6842 1.38917 11.2946 0.999599V0.999599Z" fill="#232F5E"/>
                            </svg>

                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {DropdownItem("/customer_stories", "Customer Stories")}
                            {/* {DropdownItem("/insights", "Account Insights")} */}
                            {/*{DropdownItem("/chrome", "Chrome Extension")}*/}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </li>
                  {/* <!-- End Button Write a review --> */}

                  {/* <!-- Button Sign in --> */}
                  <li
                    className="nav-item u-header__nav-item hs-sub-menu-opened my-3 showSignIn"
                    data-event="hover"
                    data-animation-in="slideInUp"
                    data-animation-out="fadeOut"
                    data-position="left"
                  >
                    <a
                      data-action="tapped sign in"
                      data-label="navigation"
                      data-category="homepage"
                      data-overlay-color="#111722"
                      data-modal-target="#signInModal"
                      className="ga btn btn-sm btn-link small headerSignIn font-weight-bold customer-stories__nav"
                      href={`/users/${isSignedIn ? "sign_out" : "sign_in"}`}
                    >
                      {isSignedIn ? "Logout" : "Sign in"}
                    </a>
                  </li>
                  {/* <!-- End Button Sign in --> */}
                  {/* <!-- Button Get Started --> */}
                  <li
                    className="nav-item u-header__nav-item hs-sub-menu-opened my-3 Signup desktop-view"
                    data-animation-in="fadeIn"
                    data-event="hover"
                    data-animation-out="fadeOut"
                  >
                    {(!isSignedIn && (
                      <a
                        className="btn btn-sm btn-primary u-btn-primary transition-3d-hover join-now__btn"
                        href="/users/sign_up?plan_type=free"
                      >
                        Get Started
                      </a>
                    )) ||
                      (isSignedIn && (
                        <a
                          className="btn btn-sm  go-to-account__btn pr-3 pl-3 pt-1 pb-1"
                          href="/account/dashboard"
                        >
                           Go to account
                        </a>
                      ))}
                  </li>
                  {/* <!-- End Button Get Started --> */}
                </ul>
              </div>
            </CSSTransition>
          {/* )} */}
        </nav>
      </div>
    </header>
  );

  function DropdownItem(dropDownLocation, dropDownText) {
    return <Dropdown.Item
      className="btn btn-sm btn-link font-weight-bold"
      href={dropDownLocation}
    >
      {dropDownText}
    </Dropdown.Item>;
  }
};

Header.propTypes = {
  userData: PropTypes.object.isRequired,
  magickLinkCta: PropTypes.string,
  showSearchBar: PropTypes.bool,
  campaignManager: PropTypes.bool,
};

export default Header;
