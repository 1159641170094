import React from "react";

const TalkToSales = () => {
    const trackEvent = () => {
        analytics.track("Clicked Talk to Sales CTA", {
            position: "middle"
        });
    };

  return (
    <div className="talk-to-sales">
      <div className="text-center">
        <a
          className="talk-to-sales-btn btn btn-primary btn-sm transition-3d-hover"
          href="/contact"
          onClick={trackEvent}
        >
          Talk to Sales{" "}
        </a>
      </div>
    </div>
  );
};

export default TalkToSales;
