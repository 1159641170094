import axios from "axios";
import request from "axios";

const getHeaders = () => {
  const csrf = document.querySelector("meta[name='csrf-token']").getAttribute('content');
  const headers = {
    'Content-Type': 'application/json',
    'X-CSRF-Token': csrf,
  };
  return headers;
};


export const sendRequest = (url, apiParams) => {
  return request({
    method: 'GET',
    url: url,
    headers: getHeaders(),
    params: apiParams,
    responseType: "json"
  });
}

export const sendPostRequest = (url, apiParams) => {

  return request({
    method: "POST",
    url: url,
    headers: getHeaders(),
    responseType: 'json',
    data: apiParams
  });
}

export const sendAwaitPostRequest = async (url, apiParams) => {

  return await axios.post(url, apiParams, {
    headers: getHeaders(),
    responseType: 'json',
  })
  // return await request({
  //   method: "POST",
  //   url: url,
  //   headers: getHeaders(),
  //   responseType: 'json',
  //   data: apiParams
  // });
}

export const uploadFile = (url, apiParams) => {
  return request({
    method: 'post',
    url: url,
    contentType: false,
    processData: false,
    header: getHeaders(),
    responseType: "json",
    data: apiParams
  })
}