import * as apiCall from "../../axios";

let apiVersion = 'v3';

export const validateEmail = (data) => {
  return apiCall.sendPostRequest('/check_user_exist', { email: data });
}

export const registerNewUser = (formData) => {
  return apiCall.sendPostRequest('/user_sign_up_api', formData);
}

export const loginUser = (formData) => {
  return apiCall.sendPostRequest("/user_login_api", formData);
}
