import React from "react";

export const Footer = () => (
  <footer className="site-footer" id="footer">
    <div className="container-fluid">
      <div className="row justify-content-lg-start">
        <div className="col-12 col-md-4 order-md-0 order-2">
          <a
            className="mb-6 d-none d-md-block"
            href="/"
            aria-label="Accountstory"
          >
            <img
              width="145px"
              src="/assets/svg/logos/Accountstory_Logo.svg"
              alt="Accountstory Logo"
            />
          </a>
          <div className="copyright-text">
            © Accountstory, Inc. {new Date().getFullYear()}. All rights reserved.
          </div>
        </div>
        <div className="col-12 col-md-8">
          <div className="row d-md-none">
            <div className="col-12">
              <a
                className="mb-7 navbar-brand d-md-none account-story-logo__footer-container"
                href="/"
                aria-label="Accountstory"
              >
                <img
                  width="145px"
                  src="/assets/svg/logos/Accountstory_Logo.svg"
                  alt="Accountstory Logo"
                  className="account-story-logo__footer"
                />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4 pr-lg-0 pl-lg-7">
              <h6>PRODUCTS</h6>
              <ul className="list-unstyled u-list">
                <li className="mb-2">
                  <a
                      className="link"
                      style={{ fontSize: "1em" }}
                      href="/campaign"
                  >
                    Campaign Manager
                  </a>
                </li>
                <li className="">
                  <a
                    className="link"
                    style={{ fontSize: "1em" }}
                    href="/insights"
                  >
                    Account Insights
                  </a>
                </li>
                {/*<li className="mb-2">*/}
                {/*  <a*/}
                {/*    className="link"*/}
                {/*    style={{ fontSize: "1em" }}*/}
                {/*    href="/chrome"*/}
                {/*  >*/}
                {/*    Chrome Extension*/}
                {/*  </a>*/}
                {/*</li>*/}
              </ul>
            </div>
            <div className="col-12 col-md-6 col-lg-4 pr-xl-0 pl-xl-8 footer-contact__container">
              <h6>CONTACT</h6>
              <ul className="list-unstyled u-list">
                <li style={{fontSize: "14px"}}>
                  <b>General Inquiries</b><br/>
                  <a className="link" href="mailto:help@accountstory.com">
                    help@accountstory.com
                  </a>
                </li>
                <li style={{fontSize: "14px"}} className="mt-1">
                  <b>Sales</b><br/>
                  <a className="link" href="mailto:sales@accountstory.com">
                    sales@accountstory.com
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-12 col-md-6 col-lg-4 pr-xl-0 pl-xl-7">
              <h6>LEGAL</h6>
              <ul className="list-unstyled u-list">
                <li className="mb-0">
                  <a className="link" href="/privacy">
                    Privacy
                  </a>
                </li>
                <li className="mb-5">
                  <a
                    className="link"
                    href="https://accountstory300699.typeform.com/to/HSYkXC"
                  >
                    Do Not Sell My info
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);
