import React from "react";
import Icons from "../../../assets/images/sprite-icons.svg";
import QuoteImage from "../../../assets/images/quote.svg";
import ClientStars from "../../../assets/images/clients-stars.svg";

const WhatWeDo = () => {
  return (
    <div className="what-we-do">
      <div className="bubble three"></div>
      <div className="bubble four"></div>
      <div className="label text-md-center">WHAT WE DO?</div>
      <h2 className="title text-md-center">
        We schedule conversations with your target decision makers and send them
        to your inbox weekly
      </h2>
      <div className="we-do">
        <div className="item">
          <svg className="icon dart">
            <use xlinkHref={Icons + "#icon-dart"}></use>
          </svg>
          <h4>Survey & Targeting</h4>
          <p className="mr-4">
            It starts with a quick survey to understand your business. We
            leverage datas to pick the best prospects to send campaigns to.
          </p>
        </div>
        <div className="item">
          <svg className="icon message">
            <use xlinkHref={Icons + "#icon-message"}></use>
          </svg>
          <h4>AI powered Copy and Messaging Automation</h4>
          <p>
            Our team and tools will put together creative messaging that
            converts and resonates with your clients.
          </p>
        </div>
        <div className="item">
          <svg className="icon send">
            <use xlinkHref={Icons + "#icon-send"}></use>
          </svg>
          <h4>Meetings delivered to your inbox</h4>
          <p>
            We do omnichannel outreach. Once we schedule a meeting with the
            right person, we send that straight your inbox so you can close the
            deal.
          </p>
        </div>
        <div className="item">
          <svg className="icon text">
            <use xlinkHref={Icons + "#icon-rocket"}></use>
          </svg>
          <h4>Reporting</h4>
          <p>
            Get custom reporting of the campaigns which are A/B tested for the
            best results.
          </p>
        </div>

        <div>
          <div className="what-we-do-client-feedback">
            <div className="quote-section">
              <img src={QuoteImage} />
            </div>
            <div className="mt-2 description-box">
              We’ve gotten some really great leads from this platform. So much
              so that I am hiring a second resource at Accountstory.
            </div>
            <div className="mt-2 client-identity">
              SVP Sales & Marketing
              <br /> Medical Software & Services Company
            </div>
            <div className="mt-2 stars-box">
              <img src={ClientStars} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeDo;
