import React from "react";
// import bgImage from "../../../assets/images/bg.png";
import bgImage from "../../../assets/images/bg-new.svg";

const GetStarted = () => {
  const trackEvent = () => {
    analytics.track("Clicked Talk to Sales CTA", {
      position: "bottom"
    });
  };

  return (
    <section
      className="get-started"
      id="get-started"
      style={{ backgroundImage: 'url("' + bgImage + '")' }}
    >
      <div className="text-center">
        <h1 className="font-weight-bold text-white mb-4">Get started</h1>
        <a
          className="get-started-btn btn btn-sm transition-3d-hover mt-2"
          href="/account/campaign_manager/new"
        >
          Create Campaign{" "}
        </a>
        <a
          className="talk-to-sales-btn btn btn-sm ml-2 transition-3d-hover mt-2"
          href="/contact"
          onClick={trackEvent}
        >
          Talk to sales
        </a>
      </div>
    </section>
  );
};

export default GetStarted;
